import React from 'react'
import './Home.scss'
import { Helmet } from 'react-helmet'
import { useIdentityContext } from 'react-netlify-identity'
import { Redirect } from 'react-router-dom'

const Home = (): JSX.Element => {
  const { user } = useIdentityContext()

  return (
    <>
      <Helmet>
        <title>Voorhoede Support</title>
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      {user ? <Redirect to="/user" /> : <Redirect to="/login" />}
    </>
  )
}

export default Home
