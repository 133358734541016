import * as React from 'react'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { useIdentityContext } from 'react-netlify-identity'

interface IPrivateRouteProps extends RouteProps {
  // adding next comment because props to ComponentType can be anything
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
  admin?: boolean
}

function PrivateRoute({ component: Component, admin, ...rest }: IPrivateRouteProps): JSX.Element {
  const { location }: RouteComponentProps<{ id: string }> = useReactRouter()
  const { user } = useIdentityContext()
  const roles = user ? user.app_metadata.roles : []

  return (
    <Route
      {...rest}
      render={(): JSX.Element | null => {
        return (
          <>
            {user && (admin ? roles && roles.includes('admin') : true) ? (
              <Component {...rest} />
            ) : (
              <Redirect to={`/login?referrer=${location.pathname}${location.search}`} />
            )}
          </>
        )
      }}
    />
  )
}

export default PrivateRoute
