import React from 'react'
import './Button.scss'

const Button = ({ ...rest }): JSX.Element => {
  const { children } = rest

  return (
    <button {...rest} className="button">
      <span className="button__inner">{children}</span>
    </button>
  )
}

export default Button
