import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import initIdentityWidget from './lib/initIdentityWidget'

export const url = 'https://support.voorhoede.nl'

const AppWithStore = (): JSX.Element => <App />

const rootElement = document.getElementById('root')

async function initRender(): Promise<void> {
  await initIdentityWidget()

  if (rootElement) {
    ReactDOM.render(<AppWithStore />, rootElement)
  }
}

initRender()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
