async function initIdentityWidget(): Promise<void> {
  const { location } = window

  if (
    location.hash.includes('recovery_token') ||
    location.hash.includes('confirmation_token') ||
    location.hash.includes('invite_token') ||
    location.hash.includes('register')
  ) {
    const widget = (await import('netlify-identity-widget')).default
    widget.init({
      container: '#widget',
    })

    if (location.hash.includes('register')) {
      widget.open('signup')
    }
  }
}

export default initIdentityWidget
