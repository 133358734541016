import React from 'react'
import { ApolloProvider, ApolloClient, from, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import GoTrue from 'gotrue-js'
import './App.scss'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { IdentityContextProvider } from 'react-netlify-identity'
import { url } from './index'
import Home from './views/Home/Home'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import DefaultLayout from './components/DefaultLayout/DefaultLayout'
const Services = React.lazy(() => import(/* webpackChunkName: "services" */ './views/Services/Services'))
const User = React.lazy(() => import(/* webpackChunkName: "user" */ './views/User/User'))
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ './views/Login/Login'))
const Website = React.lazy(() => import(/* webpackChunkName: "website" */ './views/Website/Website'))
const Tv = React.lazy(() => import(/* webpackChunkName: "tv" */ './views/Tv/Tv'))

const goTrue = new GoTrue({
  APIUrl: url,
})

const httpLink = createHttpLink({
  uri: '/.netlify/functions/graphql',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.find((error) => error.extensions?.code === 'UNAUTHENTICATED')) {
    window.location.href = `${window.location.origin}/login?referrer=${window.location.pathname}${
      window.location.search ? `?${window.location.search}` : ''
    }`
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const user = goTrue.currentUser()
    return {
      headers: {
        ...headers,
        authorization: user && user.token ? `Bearer ${user.token.access_token}` : '',
      },
    }
  })

  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, httpLink]),
})

const App = (): JSX.Element => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <React.Suspense fallback="">
          <Switch>
            <IdentityContextProvider url={url}>
              <Route name="home" path="/" exact render={(): JSX.Element => <Home />} />
              <Route name="login" path="/login" exact render={(): JSX.Element => <Login />} />
              <DefaultLayout>
                <React.Suspense fallback="">
                  <PrivateRoute path="/user" component={User} />
                  <PrivateRoute path="/services" component={Services} />
                  <PrivateRoute path="/website/:id" component={Website} />
                  <PrivateRoute path="/tv" component={Tv} />
                </React.Suspense>
              </DefaultLayout>
            </IdentityContextProvider>
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </Router>
    </ApolloProvider>
  )
}

export default App
