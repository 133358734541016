import React from 'react'
import './AppHeader.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../icons/logo.svg'
import PrimaryNavigation from '../PrimaryNavigation/PrimaryNavigation'

const AppHeader = (): JSX.Element => {
  return (
    <header className="app-header">
      <div className="app-header__inner">
        <Link to="/user" className="app-header__home-link">
          <h1>
            <Logo />
            <span className="a11y-sr-only">Voorhoede Support</span>
          </h1>
        </Link>
        <PrimaryNavigation />
      </div>
    </header>
  )
}

export default AppHeader
