import React from 'react'
import './DefaultLayout.scss'
import AppHeader from '../AppHeader/AppHeader'

interface IProps {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: IProps): JSX.Element => {
  return (
    <>
      <AppHeader />
      {children}
    </>
  )
}

export default DefaultLayout
