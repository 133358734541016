import React from 'react'
import './PrimaryNavigation.scss'
import { ReactComponent as IconMenuExit } from '../../icons/icon_menu-exit.svg'
import { ReactComponent as IconMenu } from '../../icons/icon_menu-passive.svg'
import { NavLink } from 'react-router-dom'
import Button from '../Button/Button'
import { useMediaLayout } from 'use-media'
import { useIdentityContext } from 'react-netlify-identity'
import useReactRouter from 'use-react-router'

const PrimaryNavigation = (): JSX.Element => {
  const mobileNav = useMediaLayout({ maxWidth: 640 })
  const [showNav, setShowNav] = React.useState(false)
  const { logoutUser, user } = useIdentityContext()
  const { history } = useReactRouter()
  const [routes, setRoutes] = React.useState([
    {
      type: 'navlink',
      value: 'Websites',
      path: `/user`,
    },
  ])

  React.useEffect(() => {
    if (user?.app_metadata?.roles?.includes('admin')) {
      setRoutes([
        ...routes,
        {
          type: 'navlink',
          value: 'Services',
          path: `/services`,
        },
      ])
    }
    // eslint-disable-next-line
  }, [])

  async function logout(): Promise<void> {
    await logoutUser()
    history.push('/')
  }

  return (
    <>
      {mobileNav && (
        <button className="primary-navigation--trigger" onClick={(): React.ComponentState => setShowNav(!showNav)}>
          {showNav ? <IconMenuExit /> : <IconMenu />}
          <span className="a11y-sr-only">Open menu</span>
        </button>
      )}
      {(!mobileNav || showNav) && (
        <nav className="primary-navigation">
          <ul>
            {routes &&
              routes.map((route, index) => (
                <li key={index}>
                  {route.type === 'navlink' && (
                    <NavLink
                      to={`${route.path}`}
                      className="primary-navigation-link"
                      activeClassName="primary-navigation-link--active"
                    >
                      {route.value}
                    </NavLink>
                  )}
                </li>
              ))}
            <li>
              <div className="primary-navigation-link">
                <Button onClick={logout}>Log out</Button>
              </div>
            </li>
          </ul>
        </nav>
      )}
    </>
  )
}

export default PrimaryNavigation
